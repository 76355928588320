import React, { useEffect, useState } from 'react';
import SimpleChart from './SimpleChart';

function FleetCharts({ data, vehicle, setSelectedRange }) {
  const [options, setOptions] = useState({
    chart: {
      type: 'column',
    },
    credits: {
      enabled: false,
    },
    title: {
      text: null,
    },
    plotOptions: {
      column: {
        pointPadding: 0.2,
        groupPadding: 0.2,
        borderWidth: 0,
        color: '#3B786E',
      },
    },
    series: [
      {
        showInLegend: false,
        name: '',
        data: [],
      },
    ],
  });

  useEffect(() => {
    if (data) {
      setOptions({
        ...options,
        xAxis: {
          startOnTick: false,
          endOnTick: false,
          lineWidth: 0,
          tickWidth: 0,
          categories: data.xAxis,
          crosshair: true,
          title: {
            text: data.xTitle,
          },
        },
        yAxis: {
          title: {
            text: data.yTitle,
          },
        },
        tooltip: {
          formatter: function () {
            return `<b><b>${this.point.series.yAxis.userOptions.title.text}:${this.point.options.y.toFixed(2)}</b></b>`;
          },
        },
        series: [
          {
            showInLegend: false,
            name: '',
            data: data.data.map(item => item.y),
            point: {
              events: {
                click: function () {
                  setSelectedRange(this.category.name);
                },
              },
            },
          },
        ],
      });
    }
  }, [data, vehicle]);
  return (
    <div
      style={{
        width: '100%',
        height: '450px',
        border: '1px solid #0000001F',
      }}>
      <SimpleChart options={options} />
    </div>
  );
}

export default FleetCharts;
