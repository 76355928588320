import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import CustomPicker from '../CustomPicker/CustomPicker';
import { getStartEndDate } from '../CustomPicker/utils';
import Loading from '../Loading';
import FleetCharts from './FleetCharts';
import ProductsList from './ProductsList';
import './styles.scss';

const FleetUsage = ({
  vehicleList,
  getFleetUsageDetails,
  fleetUsageDetails,
  loadingFleetUsageSelector,
}) => {
  const [selectedOption, setSelectedOption] = useState({
    variable: 'Temperature',
    vehicle: 'All Vehicles',
  });
  const [selectedRange, setSelectedRange] = useState();

  const [pickerValue, setpickerValue] = useState();
  const usageTypes = ['Temperature', 'SOC', 'Power', 'DoD'];
  const handleChange = e => {
    setSelectedOption({ ...selectedOption, [e.target.name]: e.target.value });
    const url = new URL(window.location.href);
    url.searchParams.set(e.target.name, e.target.value);
    window.history.pushState(null, null, url);
  };

  useEffect(() => {
    const url = new URL(window.location.href);
    url.searchParams.set('th', pickerValue);
    url.searchParams.set('ut', selectedOption.variable);
    url.searchParams.set('vh', selectedOption.vehicle);
    window.history.pushState(null, null, url);
  }, [pickerValue, selectedOption.variable, selectedOption.vehicle]);

  useEffect(() => {
    const url = new URL(window.location.href);
    url.searchParams.set('ut', 'Temperature');
    url.searchParams.set('vh', 'All Vehicles');
    url.searchParams.set('th', new Date().getFullYear());
    window.history.pushState(null, null, url);
    handleApply();
  }, [new URL(window.location.href).searchParams.get('ft')]);

  const handleApply = () => {
    const url = new URL(window.location.href);
    const { startDate, endDate } = getStartEndDate(pickerValue);
    getFleetUsageDetails(
      url.searchParams.get('ut'),
      url.searchParams.get('ft'),
      url.searchParams.get('vh'),
      startDate.toISOString().split('T')[0] + 'T00:00:00Z',
      endDate.toISOString().split('T')[0] + 'T23:59:59Z'
    );
  };
  if (!vehicleList) return <Loading />;
  console.log(fleetUsageDetails, 'fleetUsageDetails');
  return (
    <div className="fleetUsage">
      <div className="row w-100">
        <Form.Group as={Col}>
          <Form.Label>Select variable</Form.Label>
          <Form.Select
            name="ut"
            defaultValue={selectedOption.variable}
            className="rounded-0"
            aria-label="Select Temperature"
            onChange={e => handleChange(e)}>
            {usageTypes.map(usageType => (
              <option key={usageType} value={usageType}>
                {usageType}
              </option>
            ))}
          </Form.Select>
        </Form.Group>

        <Form.Group as={Col}>
          <Form.Label>Select vehicle</Form.Label>

          <Form.Select
            name="vh"
            className="rounded-0"
            aria-label="Select Temperature"
            defaultValue={selectedOption.vehicle}
            onChange={e => handleChange(e)}>
            <option key="All Vehicles" value="All Vehicles">
              All Vehicles
            </option>
            {vehicleList[0].vst.map(vehicle => (
              <option key={vehicle.vn} value={vehicle.vn}>
                {vehicle.dvn}
              </option>
            ))}
          </Form.Select>
        </Form.Group>

        <Form.Group as={Col}>
          <Form.Label>Select time horizon</Form.Label>

          <CustomPicker
            pickerValue={pickerValue || new Date().getFullYear()}
            setpickerValue={setpickerValue}
          />
        </Form.Group>

        <Form.Group as={Col}>
          <Button
            className="mt-4 rounded-0"
            variant="primary"
            onClick={() => handleApply()}>
            Apply
          </Button>
        </Form.Group>
      </div>

      <div
        style={{
          height: '500px',
        }}>
        {fleetUsageDetails !== null ? (
          <FleetCharts
            data={fleetUsageDetails}
            vehicle={selectedOption.vehicle}
            setSelectedRange={setSelectedRange}
          />
        ) : (
          <Loading />
        )}
      </div>

      <div>
        {fleetUsageDetails !== null &&
        fleetUsageDetails.details.length > 0 &&
        selectedRange ? (
          <ProductsList
            productList={fleetUsageDetails}
            selectedRange={selectedRange}
          />
        ) : (
          ''
        )}
      </div>
    </div>
  );
};

export default FleetUsage;
