import React from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Map from '../Map/Map';
import MapSearch from '../Map/MapSearch';
import SiteSetupMap from '../SiteSetupMap';
import './styles.scss';

class SiteSetupForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      validated: false,
      visible: false,
      isSiteNameUsed: false,
      showAzureMap: true,
    };
  }

  checkSiteNameUsed = e => {
    this.props.handleSiteInputChange(e);
    let isUsed = false;

    if (this.props.editSite === true) {
      isUsed = this.props.setupSiteList[0].sites.some(
        element =>
          element.displaySiteName.toLowerCase() === e.target.value.toLowerCase()
      );
    } else {
      isUsed = this.props.setupSidemenuList[0].sites.some(
        element =>
          element.displaySiteName.toLowerCase() === e.target.value.toLowerCase()
      );
    }

    if (isUsed === true) {
      this.setState({ isSiteNameUsed: true });
    } else {
      this.setState({ isSiteNameUsed: false });
    }
  };

  handleSubmit = e => {
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
      this.setState({ validated: true });
    } else if (form.checkValidity() === true) {
      this.props.handleSiteSubmit(e);
      this.setState({ validated: false, visible: true });
      setTimeout(() => {
        this.setState({
          visible: false,
        });
      }, 7000);
    }
  };

  render() {
    return (
      <div className="formfleetsetupview">
        <Form
          noValidate
          validated={this.state.validated}
          onSubmit={this.handleSubmit}>
          <Col className="containertitlebar">
            <>
              <span className="text ">
                {this.props.siteFormValues.displaySiteName}
              </span>
            </>

            <span className="float-right">
              <button
                type="button"
                className="smallSilverButton btn  btn-sm"
                disabled={this.state.isSiteNameUsed}
                onClick={this.props.handleSiteReset}>
                Reset
              </button>
              <Button
                type="submit"
                className="smallButton btn btn-primary btn-sm"
                disabled={this.state.isSiteNameUsed}>
                {this.props.loadingsavesite !== true ? 'Save' : 'Saving data'}
              </Button>
            </span>
          </Col>
          <div className="frmsection1">
            <Row className="frmcontrols">
              <Col md={4} controlId="maintenancesiteLoc">
                <Form.Label>Maintenance site location</Form.Label>

                <MapSearch
                  onPlaceSelected={this.props.onPlaceSelected}
                  defaultPlace={
                    this.props.siteFormValues.siteAddress.length > 0
                      ? {
                          formatted_address: this.props.siteFormValues.sitelocN,
                          geometry: {
                            location: {
                              lat: this.props.siteFormValues.siteAddress[0].lat,
                              lng: this.props.siteFormValues.siteAddress[0].lng,
                            },
                          },
                        }
                      : {
                          formatted_address: null,
                          geometry: {
                            location: { lat: 53.201309, lng: 5.81963 },
                          },
                        }
                  }
                />
                <Form.Control.Feedback type="invalid">
                  Please provide maintenance site location.
                </Form.Control.Feedback>
              </Col>
              <Col md={4} controlId="Maintenancesitename">
                <Form.Label>Maintenance site name</Form.Label>
                <Form.Control
                  hidden
                  autoComplete="off"
                  type="text"
                  placeholder=""
                  name="siteName"
                  // onChange={this.checkSiteNameUsed}
                  defaultValue={this.props.siteFormValues.siteName}
                />
                <Form.Control
                  required
                  autoComplete="off"
                  type="text"
                  placeholder=""
                  name="displaySiteName"
                  onChange={this.checkSiteNameUsed}
                  value={this.props.siteFormValues.displaySiteName}
                />

                <Form.Control.Feedback type="invalid">
                  Please provide maintenance site name.
                </Form.Control.Feedback>
                <div className="alreadyusedmsg">
                  {this.state.isSiteNameUsed === true
                    ? 'Site name already in use. Try another.'
                    : ''}
                </div>
              </Col>{' '}
              <Col md={4} controlId="Maintenance site ID">
                <Form.Label>Maintenance site ID</Form.Label>
                <Form.Control
                  required
                  autoComplete="off"
                  type="text"
                  placeholder=""
                  name="siteID"
                  onChange={this.props.handleSiteInputChange}
                  value={this.props.siteFormValues.siteID}
                  readOnly
                />
                <Form.Control.Feedback type="invalid">
                  Please provide maintenance site ID.
                </Form.Control.Feedback>
              </Col>
            </Row>
            <div className="sitesetmapcon">
              {!this.state.showAzureMap ? (
                <SiteSetupMap
                  markerpostion={
                    this.props.siteFormValues.siteAddress.length > 0
                      ? this.props.siteFormValues.siteAddress
                      : [{ lat: 53.201309, lng: 5.81963 }]
                  }
                />
              ) : (
                <Map
                  mapdata={
                    this.props.siteFormValues.siteAddress.length > 0
                      ? {
                          lat: this.props.siteFormValues.siteAddress[0].lat,
                          lng: this.props.siteFormValues.siteAddress[0].lng,
                          id: 1,
                        }
                      : { lat: 53.201309, lng: 5.81963, id: 1 }
                  }
                  mapHeight={'50vh'}
                  mapType={'simple'}
                />
              )}
            </div>
            <Row className="frmcontrols">
              <Col md={6} controlId="siteOpName">
                <Form.Label>Operator name</Form.Label>
                <Form.Control
                  required
                  autoComplete="off"
                  type="text"
                  placeholder=""
                  name="siteOpname"
                  onChange={this.props.handleSiteInputChange}
                  value={this.props.siteFormValues.siteOpname}
                />
                <Form.Control.Feedback type="invalid">
                  Please provide operator name.
                </Form.Control.Feedback>
              </Col>

              <Col md={6} controlId="opID">
                <Form.Label>Operator ID</Form.Label>
                <Form.Control
                  required
                  autoComplete="off"
                  type="text"
                  placeholder=""
                  name="siteOpID"
                  onChange={this.props.handleSiteInputChange}
                  value={this.props.siteFormValues.siteOpID}
                />
                <Form.Control.Feedback type="invalid">
                  Please provide operator ID.
                </Form.Control.Feedback>
              </Col>
              <Col md={6} controlId="Name of contact">
                <Form.Label>Name of contact</Form.Label>
                <Form.Control
                  required
                  autoComplete="off"
                  type="text"
                  placeholder=""
                  name="siteContactP"
                  onChange={this.props.handleSiteInputChange}
                  value={this.props.siteFormValues.siteContactP}
                />
                <Form.Control.Feedback type="invalid">
                  Please provide valid name of contact.
                </Form.Control.Feedback>
              </Col>
              <Col md={6} controlId="Job function of contact">
                <Form.Label>Job function of contact</Form.Label>
                <Form.Control
                  required
                  autoComplete="off"
                  type="text"
                  placeholder=""
                  name="jobFunContct"
                  onChange={this.props.handleSiteInputChange}
                  value={this.props.siteFormValues.jobFunContct}
                />
                <Form.Control.Feedback type="invalid">
                  Please provide job function of contact.
                </Form.Control.Feedback>
              </Col>
              <Col md={6} controlId="Email address">
                <Form.Label>Email address</Form.Label>
                <Form.Control
                  required
                  autoComplete="off"
                  type="email"
                  placeholder=""
                  name="siteEmail"
                  onChange={this.props.handleSiteInputChange}
                  value={this.props.siteFormValues.siteEmail}
                />
                <Form.Control.Feedback type="invalid">
                  Please provide email address.
                </Form.Control.Feedback>
              </Col>
              <Col md={6} controlId="Phone number">
                <Form.Label>Phone number</Form.Label>
                <Form.Control
                  required
                  autoComplete="off"
                  type="number"
                  placeholder=""
                  name="sitePhone"
                  onChange={this.props.handleSiteInputChange}
                  value={this.props.siteFormValues.sitePhone}
                />
                <Form.Control.Feedback type="invalid">
                  Please provide phone number.
                </Form.Control.Feedback>
              </Col>
            </Row>
          </div>
        </Form>
      </div>
    );
  }
}

export default SiteSetupForm;
