import moment from 'moment';
import React from 'react';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import { Edit, Rightmark, TrashIcon } from '../../assets/images';
import CollapsableSection from '../CollapsableSection';
import Help from '../Help/Help';
import './styles.scss';

class ContractorForm extends React.Component {
  constructor() {
    super();
    this.state = {
      userGroupInt: JSON.parse(sessionStorage.getItem('groups')),
      userGroup: '',
      selectedDate: new Date().toLocaleDateString('en-US'),
      notificationInterval: '',
      notificationEnabled: false,
      userGroupActive: false,
      editingIndex: null,
    };
  }

  handleInputChange = (accessInputType, value, index = null) => {
    if (index === null) {
      this.setState({ [accessInputType]: value });
    } else {
      const { accessExpirationDetailList } = this.props.fleetFormValues;
      const updatedList = [...accessExpirationDetailList];
      updatedList[index] = { ...updatedList[index], [accessInputType]: value };
      this.props.onUpdateNotifications(updatedList);
    }
  };

  handleDateChange = (picker, index = null) => {
    const startDate = picker.startDate.format('MM/DD/YYYY');
    this.handleInputChange('selectedDate', startDate, index);
  };

  handleAddNotification = () => {
    const {
      userGroup,
      selectedDate,
      notificationInterval,
      notificationEnabled,
      userGroupActive,
    } = this.state;

    this.props.onAddNotification({
      userGroup,
      selectedDate,
      notificationInterval,
      notificationEnabled,
      userGroupActive,
      isEditing: false,
    });

    this.setState({
      userGroup: '',
      selectedDate: new Date().toLocaleDateString('en-US'),
      notificationInterval: '',
      notificationEnabled: false,
      userGroupActive: false,
    });
  };

  handleEditNotification = index => {
    const { accessExpirationDetailList } = this.props.fleetFormValues;
    const editingItem = accessExpirationDetailList[index];

    if (editingItem.isEditing) {
      this.props.onUpdateNotifications(accessExpirationDetailList);
    }

    const updatedList = [...accessExpirationDetailList];
    updatedList[index].isEditing = !editingItem.isEditing;
    this.props.onUpdateNotifications(updatedList);
  };

  isAddNotificationDisabled = () => {
    const { userGroup, selectedDate, notificationInterval } = this.state;
    return !(userGroup && selectedDate && notificationInterval);
  };

  customFormInput = (
    label,
    name,
    type,
    value,
    options = [],
    index = null,
    helpContent = '',
    disabled = false
  ) => (
    <Form.Group as={Col} md={3}>
      <Form.Label>
        {label}{' '}
        {helpContent && <Help helpContent={helpContent} placement={'left'} />}
      </Form.Label>
      {type === 'select' ? (
        <Form.Control
          as="select"
          className="custom-select"
          name={name}
          onChange={e => this.handleInputChange(name, e.target.value, index)}
          value={value}
          disabled={disabled}>
          <option value="">Please select</option>
          {options.map((option, idx) => (
            <option value={option} key={idx}>
              {option}
            </option>
          ))}
        </Form.Control>
      ) : (
        <DateRangePicker
          onApply={(event, picker) => this.handleDateChange(picker, index)}
          initialSettings={{
            singleDatePicker: true,
            showDropdowns: true,
            minDate: moment().format('MM/DD/YYYY'),
          }}>
          <Form.Control
            autoComplete="off"
            type="text"
            name={name}
            value={value && value}
            disabled={disabled}
            className="datepickervalue"
          />
        </DateRangePicker>
      )}
    </Form.Group>
  );

  render() {
    const { userGroupInt } = this.state;
    const { onDeleteNotification } = this.props;
    const { accessExpirationDetailList, isUpdate, isDraft } =
      this.props.fleetFormValues;
    const isDisabled = isUpdate && !isDraft;

    return (
      <div>
        <CollapsableSection title={'Access expiration data'}>
          <div
            id="contractor_status"
            style={{
              display: 'flex',
              flexDirection: 'column',
            }}>
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <button
                type="button"
                style={{
                  fontSize: '14px',
                  height: '30px',
                  border: '1px solid rgba(200, 200, 200, 0.60)',
                  background: 'rgba(220, 220, 220, 0.60)',
                  boxShadow: '0px -1px 0px 0px #A0A0A0 inset',
                }}
                onClick={this.handleAddNotification}
                disabled={isDisabled || this.isAddNotificationDisabled()}>
                + Add
              </button>
            </div>
            <div
              style={{
                display: 'flex',
                borderBottom: '2px solid',
                padding: '5px 0px',
              }}>
              {this.customFormInput(
                'User Group',
                'userGroup',
                'select',
                this.state.userGroup,
                userGroupInt,
                null,
                'User Group',
                isDisabled
              )}
              {this.customFormInput(
                'Date',
                'selectedDate',
                'date',
                this.state.selectedDate,
                [],
                null,
                '',
                isDisabled
              )}
              {this.customFormInput(
                'Notification frequency',
                'notificationInterval',
                'select',
                this.state.notificationInterval,
                this.props.fleetSetupData.map(({ vl }) => vl),
                null,
                'Notification',
                isDisabled
              )}
              <Form.Check
                type="switch"
                label=""
                name="notificationEnabled"
                onChange={e =>
                  this.handleInputChange(
                    'notificationEnabled',
                    e.target.checked
                  )
                }
                checked={this.state.notificationEnabled}
                disabled={isDisabled}
                style={{ marginTop: '35px', marginRight: '30px' }}
              />
              <Form.Group as={Col} md={3}>
                <div
                  style={{
                    height: '60px',
                    display: 'inline-flex',
                    alignItems: 'flex-end',
                  }}>
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <Form.Label>Activation</Form.Label>
                    <Form.Check
                      type="switch"
                      label=""
                      name="userGroupActive"
                      onChange={e =>
                        this.handleInputChange(
                          'userGroupActive',
                          e.target.checked
                        )
                      }
                      checked={this.state.userGroupActive}
                      disabled={isDisabled}
                    />
                  </div>
                </div>
              </Form.Group>
            </div>
          </div>

          <div>
            {accessExpirationDetailList?.map(
              (accessExpirationDetail, index) => (
                <li key={index} style={{ listStyle: 'none' }}>
                  <div id="contractor_status" style={{ display: 'flex' }}>
                    {this.customFormInput(
                      '',
                      'userGroup',
                      'select',
                      accessExpirationDetail.userGroup,
                      userGroupInt,
                      index,
                      '',
                      !accessExpirationDetail.isEditing
                    )}
                    {this.customFormInput(
                      '',
                      'selectedDate',
                      'date',
                      moment(accessExpirationDetail.selectedDate).format(
                        'MM/DD/YYYY'
                      ),
                      [],
                      index,
                      '',
                      !accessExpirationDetail.isEditing
                    )}
                    {this.customFormInput(
                      '',
                      'notificationInterval',
                      'select',
                      accessExpirationDetail.notificationInterval,
                      this.props.fleetSetupData.map(({ vl }) => vl),
                      index,
                      '',
                      !accessExpirationDetail.isEditing
                    )}
                    <Form.Check
                      type="switch"
                      label=""
                      name="notificationEnabled"
                      onChange={e =>
                        this.handleInputChange(
                          'notificationEnabled',
                          e.target.checked,
                          index
                        )
                      }
                      checked={accessExpirationDetail.notificationEnabled}
                      disabled={!accessExpirationDetail.isEditing}
                      style={{ marginTop: '25px', marginRight: '30px' }}
                    />
                    <Form.Group as={Col} md={3}>
                      <div
                        style={{
                          height: '50px',
                          display: 'inline-flex',
                          alignItems: 'flex-end',
                        }}>
                        <Form.Check
                          type="switch"
                          label=""
                          name="userGroupActive"
                          onChange={e =>
                            this.handleInputChange(
                              'userGroupActive',
                              e.target.checked,
                              index
                            )
                          }
                          checked={accessExpirationDetail.userGroupActive}
                          disabled={!accessExpirationDetail.isEditing}
                        />
                        <button
                          type="button"
                          style={{
                            background: 'transparent',
                            paddingLeft: '40px',
                          }}
                          onClick={() => this.handleEditNotification(index)}>
                          {accessExpirationDetail.isEditing ? (
                            <Rightmark />
                          ) : (
                            <Edit />
                          )}
                        </button>
                        <button
                          type="button"
                          style={{
                            background: 'transparent',
                            paddingLeft: '20px',
                          }}
                          onClick={() => onDeleteNotification(index)}>
                          <TrashIcon />
                        </button>
                      </div>
                    </Form.Group>
                  </div>
                </li>
              )
            )}
          </div>
        </CollapsableSection>
      </div>
    );
  }
}

export default ContractorForm;
