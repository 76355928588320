import { months } from './constants';

export const getStartEndDate = pickerValue => {
  if (!pickerValue) {
    return {
      startDate: new Date(new Date().getFullYear(), 0, 2),
      endDate: new Date(new Date().getFullYear(), 11, 31),
    };
  }
  const regex = /^(201[0-9]|20[2-9][0-9])$/;
  if (regex.test(pickerValue)) {
    // return the start and end date of the year
    return {
      startDate: new Date(pickerValue, 0, 2),
      endDate: new Date(pickerValue, 11, 31),
    };
  } else {
    const [start, end] = pickerValue.split('to');
    const [startMonth, startYear] = start.split('-');
    const [endMonth, endYear] = end.split('-');
    // get start and end date of a given month
    return {
      startDate: new Date(startYear, months.indexOf(startMonth) + 1, 0),
      endDate: new Date(
        endYear,
        months.indexOf(endMonth) + 1,
        new Date(endYear, months.indexOf(endMonth) + 1, 0).getDate()
      ),
    };
  }
};
