import Highcharts from 'highcharts/highstock';
import exportChart from '../../assets/images/exportChart.svg';
import { applicationView, platformVersion } from '../../utils/excel-utils';
// stock chart

let clickedPoint;
let clickedPointProdstr;
let clickedPointVehicle;
let clickedPointVehstr;

Highcharts.wrap(Highcharts.Tooltip.prototype, 'hide', function (proceed, hide) {
  if (hide) {
    proceed.apply(this, Array.prototype.slice.call(arguments, 1));
  }
});

Highcharts.wrap(
  Highcharts.Tooltip.prototype,
  'refresh',
  function (proceed, point, event, click) {
    if (click) {
      proceed.apply(this, Array.prototype.slice.call(arguments, 1));
    }
  }
);

function onTooltipItem(point, signal) {
  window.$redirectToTimetrend(point, signal);
}
// To enable click over tooltip item to redirect from vehicle view overview/reliability and utilization the below code can be modified or can be entirely removed.

function fleetSpecificRedirection() {
  const status =
    platformVersion() === '3' &&
    applicationView() === 'VehicleView' &&
    !new URLSearchParams(window.location.search).get('pdn');
  return status;
}

(function (H) {
  H.wrap(H.Tooltip.prototype, 'refresh', function (proceed, point) {
    proceed.apply(this, Array.prototype.slice.call(arguments, 1));

    // var button = this.label.div.querySelector("button");
    const temp = document.querySelector('#temp');
    const soc = document.querySelector('#soc');
    const crate = document.querySelector('#crate');
    const sname = document.querySelector('#sname');
    const sdate = document.querySelector('#sdate');
    const rname = document.querySelector('#rname');
    const rdate = document.querySelector('#rdate');
    const pname = document.querySelector('#pname');
    const rpname = document.querySelector('#rpname');
    // if (point.series.name === "Unknown") {
    //   return false;
    // }
    if (sname && !sname.addedEvent) {
      sname.addEventListener('click', function () {
        // eslint-disable-next-line no-unused-expressions
        fleetSpecificRedirection()
          ? onTooltipItem(point, 'sRedirect')
          : onTooltipItem(point, 'nameClicked');
      });
      sname.addedEvent = true;
    }
    if (sdate && !sdate.addedEvent) {
      sdate.addEventListener('click', function () {
        // eslint-disable-next-line no-unused-expressions
        fleetSpecificRedirection()
          ? onTooltipItem(point, 'sRedirect')
          : onTooltipItem(point, 'dateClicked');
      });
      sdate.addedEvent = true;
    }

    if (rname && !rname.addedEvent) {
      rname.addEventListener('click', function () {
        // eslint-disable-next-line no-unused-expressions
        fleetSpecificRedirection()
          ? onTooltipItem(point, 'sRedirect')
          : onTooltipItem(point, 'nameClicked');
      });
      rname.addedEvent = true;
    }
    if (rdate && !rdate.addedEvent) {
      rdate.addEventListener('click', function () {
        // eslint-disable-next-line no-unused-expressions
        fleetSpecificRedirection()
          ? onTooltipItem(point, 'sRedirect')
          : onTooltipItem(point, 'Reliability');
      });
      rdate.addedEvent = true;
    }

    if (pname && !pname.addedEvent) {
      pname.addEventListener('click', function () {
        // eslint-disable-next-line no-unused-expressions
        fleetSpecificRedirection()
          ? onTooltipItem(point, 'sRedirect')
          : onTooltipItem(point, 'pnameClicked');
      });
      pname.addedEvent = true;
    }

    if (rpname && !rpname.addedEvent) {
      rpname.addEventListener('click', function () {
        // eslint-disable-next-line no-unused-expressions
        fleetSpecificRedirection()
          ? onTooltipItem(point, 'sRedirect')
          : onTooltipItem(point, 'pnameClicked');
      });
      rpname.addedEvent = true;
    }

    if (temp && !temp.addedEvent) {
      temp.addEventListener('click', function () {
        fleetSpecificRedirection()
          ? onTooltipItem(point, 'sRedirect')
          : onTooltipItem(point, 'Temperature');
      });
      temp.addedEvent = true;
    }
    if (soc && !soc.addedEvent) {
      soc.addEventListener('click', function () {
        fleetSpecificRedirection()
          ? onTooltipItem(point, 'sRedirect')
          : onTooltipItem(point, 'State of Charge');
      });
      soc.addedEvent = true;
    }
    if (crate && !crate.addedEvent) {
      crate.addEventListener('click', function () {
        fleetSpecificRedirection()
          ? onTooltipItem(point, 'sRedirect')
          : onTooltipItem(point, 'Battery Current');
      });
      crate.addedEvent = true;
    }
  });
})(Highcharts);

export function getColomChart(
  seriesData,
  chartType,
  redirectToTimetrend,
  report = false
) {
  if (redirectToTimetrend !== undefined) {
    window.$redirectToTimetrend = redirectToTimetrend;
  }
  let rmax = 6;
  let rmin = 0;
  let smax = 6;
  let smin = 0;
  const options = {
    chart: {
      type: 'column',
      marginTop: 16,
      height: 340,
      events: {
        load: function () {
          const chart = this;
          const xAxis = chart.xAxis[0];
          chart.getSelectedPoints().forEach(function (p) {
            p.select(false);
          });
          if (chartType === 'BATT_STR_COL_TYPE') {
            const url = new URL(window.location.href);
            const minValue = url.searchParams.get('psbrmn');
            const maxValue = url.searchParams.get('psbrmx');
            if (
              minValue !== null &&
              maxValue !== null &&
              minValue !== undefined &&
              maxValue !== undefined
            ) {
              xAxis.setExtremes(Number(minValue), Number(maxValue));
            }
          } else {
            const url = new URL(window.location.href);
            const minValue = url.searchParams.get('prbrmn');
            const maxValue = url.searchParams.get('prbrmx');
            if (
              minValue !== null &&
              maxValue !== null &&
              minValue !== undefined &&
              maxValue !== undefined
            ) {
              xAxis.setExtremes(Number(minValue), Number(maxValue));
            }
          }
        },
      },
    },
    title: {
      text: '',
    },
    xAxis: {
      min: 0,
      max: report ? undefined : 6,
      categories: seriesData.dates,
      events: {
        setExtremes: function (e) {
          this.chart.tooltip.hide(true);
          if (chartType === 'BATT_STR_COL_TYPE') {
            smin = Math.round(e.min);
            smax = Math.round(e.max);
            if (smin !== undefined && smax !== undefined) {
              const url = new URL(window.location.href);
              if (window.location.href.indexOf('?') > -1) {
                url.searchParams.set('psbrmn', smin);
                url.searchParams.set('psbrmx', smax);
                window.history.replaceState(null, null, url);
              }
            } else {
              const url = new URL(window.location.href);
              if (window.location.href.indexOf('?') > -1) {
                url.searchParams.delete('psbrmn');
                url.searchParams.delete('psbrmx');
                window.history.replaceState(null, null, url);
              }
            }
          } else {
            rmin = Math.round(e.min);
            rmax = Math.round(e.max);
            if (rmin !== undefined && rmax !== undefined) {
              const url = new URL(window.location.href);
              if (window.location.href.indexOf('?') > -1) {
                url.searchParams.set('prbrmn', rmin);
                url.searchParams.set('prbrmx', rmax);
                window.history.replaceState(null, null, url);
              }
            } else {
              const url = new URL(window.location.href);
              if (window.location.href.indexOf('?') > -1) {
                url.searchParams.delete('prbrmn');
                url.searchParams.delete('prbrmx');
                window.history.replaceState(null, null, url);
              }
            }
          }
        },
      },
      scrollbar: {
        enabled: true,
      },
    },
    yAxis: {
      min: 0,
      max: 24,
      tickInterval: 2,
      title: {
        text: `<b>hrs</b>`,
        align: 'high',
      },
    },
    legend: {
      enabled: false,
    },
    credits: {
      enabled: false,
    },
    // tooltip: {
    //   headerFormat: "<b>{point.x}</b><br/>",
    //   pointFormat: "{series.name}: {point.y} hrs",
    // },
    tooltip: {
      // enabled: false,
      backgroundColor: 'none',
      borderWidth: 0,
      shadow: false,
      useHTML: true,
      padding: 0,
      // hideDelay: 6000,
      style: {
        pointerEvents: 'auto',
      },
      formatter: function () {
        if (chartType === 'BATT_STR_COL_TYPE') {
          if (
            this.series.name === 'Unknown' ||
            this.series.name === 'All thresholds met'
          ) {
            if (this.series.name === 'All thresholds met') {
              return `<div class="toolheader" id="sname">${this.series.name}  for ${this.y} hrs </div><div class="toolcontent " id="sdate">Date : ${this.x} </div> <div class="toolcontent hiderightarrow">Cycles used: ${this.point.cyu}</div>`;
            } else {
              return `<div class="toolheader" id="sname">${this.series.name} for ${this.y} hrs </div><div class="toolcontent " id="sdate">Date : ${this.x} </div>`;
            }
          } else {
            return `<div class="toolheader">${this.series.name}  for ${
              this.y
            } hrs </div><div class="toolcontent hiderightarrow">Date : ${
              this.x
            } </div><div class="toolcontent" id="temp" >Temperature: ${
              this.point.tmp
            }% </div><div class="toolcontent" id="soc">SOC: ${
              this.point.soc
            }% </div><div class="toolcontent" id="crate">C-Rate: ${
              this.point.crt
            }% </div><div class="toolcontent hiderightarrow">Cycle: ${
              this.point.cyl
            }%</div><div class="toolcontent hiderightarrow">Cycles used: ${
              this.point.cyu
            }</div><div class="toolcontent hiderightarrow">Cycles threshold: ${
              sessionStorage.getItem('selectedFleet').includes('GTW') === true
                ? 7.2098
                : 10.0
            }</div>`;
          }
        } else {
          return `<div class="toolheader" id="rname">${this.series.name} for ${this.y} hrs </div><div class="toolcontent" id="rdate">Date : ${this.x} </div>`;
        }
      },
      positioner: function (labelWidth, _, point) {
        const sName =
          this.chart.series.length > 1
            ? this.chart.series[1].name
            : this.chart.series[0].name;

        const sState =
          this.chart.series.length > 1
            ? this.chart.series[1].state
            : this.chart.series[0].state;
        return {
          x:
            point.plotX - labelWidth + 10 > 0
              ? point.plotX - labelWidth + 45
              : point.plotX + 87,
          y:
            point.plotY > 0
              ? chartType === 'BATT_STR_COL_TYPE' &&
                sName === 'One or more thresholds exceeded' &&
                sState !== 'inactive' &&
                point.plotY > 150
                ? point.plotY - 100
                : point.plotY
              : 0,
        };
      },
    },
    plotOptions: {
      column: {
        stickyTracking: false,
        // enableMouseTracking: false,
        pointWidth: report ? undefined : 24, // 24,//
        stacking: 'normal',
        cursor: 'pointer',
        allowPointSelect: true,
        states: {
          select: { color: null, borderWidth: 0, borderColor: null },
          inactive: {
            opacity: 1,
          },
        },
        dataLabels: {
          enabled: false,
        },
        point: {
          events: {
            click: function (e) {
              if (chartType === 'BATT_REL_COL_TYPE') {
                if (clickedPoint === this) {
                  this.series.chart.tooltip.hide(true);
                  clickedPoint = null;
                } else {
                  this.series.chart.tooltip.refresh(this, e, true);
                  clickedPoint = this;
                }
              } else if (chartType === 'BATT_STR_COL_TYPE') {
                if (clickedPointProdstr === this) {
                  this.series.chart.tooltip.hide(true);
                  clickedPointProdstr = null;
                } else {
                  this.series.chart.tooltip.refresh(this, e, true);
                  clickedPointProdstr = this;
                }
              }
            },
            select: function () {
              this.series.chart.update({
                tooltip: {
                  enabled: true,
                },
              });
            },
          },
        },
      },
    },
    series: seriesData.col,
    exporting: {
      buttons: {
        contextButton: {
          enabled: false,
          _id: 'custom_export',
          symbol: `url(${exportChart})`,
          symbolX: 25,
          symbolY: 40,
          align: 'right',
          verticalAlign: 'top',
          height: 28,
          width: 32,
          symbolSize: 22,
          y: 0,
          x: 10,
          menuItems: ['viewFullscreen', 'printChart'],
          theme: {
            states: {
              hover: {
                fill: 'transparent',
              },
              pressed: {
                fill: 'transparent',
              },
            },
          },
        },
      },
    },
  };

  return options;
}

export function getVehicleColomChart(
  seriesData,
  chartType,
  redirectToTimetrend
) {
  if (redirectToTimetrend !== undefined) {
    window.$redirectToTimetrend = redirectToTimetrend;
  }

  seriesData.col.forEach((obj, index) => {
    if (seriesData.col.length === 6 && index > 2) {
      if (seriesData.col[2].stack === seriesData.col[index].stack) {
        obj.stack = obj.stack + '.';
      }
    }
  });

  let max = 6;
  let min = 0;
  let rmax = 6;
  let rmin = 0;
  let smax = 6;
  let smin = 0;
  const options = {
    chart: {
      type: 'column',
      marginTop: 16,
      height: 410,
      marginLeft: 50,
      events: {
        load: function () {
          const chart = this;
          const xAxis = chart.xAxis[0];
          chart.getSelectedPoints().forEach(function (p) {
            p.select(false);
          });
          if (chartType === 'VEH_STR_COL_TYPE') {
            const url = new URL(window.location.href);
            const minValue = url.searchParams.get('vsbrmn');
            const maxValue = url.searchParams.get('vsbrmx');
            if (
              minValue !== null &&
              maxValue !== null &&
              minValue !== undefined &&
              maxValue !== undefined
            ) {
              xAxis.setExtremes(Number(minValue), Number(maxValue));
            }
          } else {
            const url = new URL(window.location.href);
            const minValue = url.searchParams.get('vrbrmn');
            const maxValue = url.searchParams.get('vrbrmx');
            if (
              minValue !== null &&
              maxValue !== null &&
              minValue !== undefined &&
              maxValue !== undefined
            ) {
              xAxis.setExtremes(Number(minValue), Number(maxValue));
            }
          }
        },
      },
    },
    title: {
      text: '',
    },
    xAxis: {
      min: 0,
      max: seriesData.col.length === 6 ? 6 : 4,
      tickWidth: 1,
      tickLength: 62,
      tickColor: '#BABABA',
      categories: seriesData.dates,

      labels: {
        useHTML: true,
        y: 60,
      },
      events: {
        setExtremes: function (e) {
          min = Math.round(e.min);
          max = Math.round(e.max);
          this.chart.tooltip.hide(true);
          if (chartType === 'VEH_STR_COL_TYPE') {
            smin = Math.round(e.min);
            smax = Math.round(e.max);
            if (smin !== undefined && smax !== undefined) {
              const url = new URL(window.location.href);
              if (window.location.href.indexOf('?') > -1) {
                url.searchParams.set('vsbrmn', smin);
                url.searchParams.set('vsbrmx', smax);
                window.history.replaceState(null, null, url);
              }
            } else {
              const url = new URL(window.location.href);
              if (window.location.href.indexOf('?') > -1) {
                url.searchParams.delete('vsbrmn');
                url.searchParams.delete('vsbrmx');
                window.history.replaceState(null, null, url);
              }
            }
          } else {
            rmin = Math.round(e.min);
            rmax = Math.round(e.max);
            if (rmin !== undefined && rmax !== undefined) {
              const url = new URL(window.location.href);
              if (window.location.href.indexOf('?') > -1) {
                url.searchParams.set('vrbrmn', rmin);
                url.searchParams.set('vrbrmx', rmax);
                window.history.replaceState(null, null, url);
              }
            } else {
              const url = new URL(window.location.href);
              if (window.location.href.indexOf('?') > -1) {
                url.searchParams.delete('vrbrmn');
                url.searchParams.delete('vrbrmx');
                window.history.replaceState(null, null, url);
              }
            }
          }
        },
      },
      scrollbar: {
        enabled: true,
      },
    },
    yAxis: {
      min: 0,
      max: 24,
      tickInterval: 2,
      title: {
        text: `<b>hrs</b>`,
        align: 'high',
      },
      stackLabels: {
        useHTML: true,
        enabled: true,
        verticalAlign: 'bottom',
        allowOverlap: true,
        crop: false,
        overflow: 'none',
        y: 35,
        formatter: function () {
          if (this.x >= min && this.x <= max) {
            // return `<div class="stackcontent">${this.stack.slice(-6)}</div>`;
            if (
              sessionStorage.getItem('selectedFleet') === 'ABB ESS Demo Fleet'
            ) {
              return `<div class="stackcontent">${this.stack.slice(-6)}</div>`;
            } else {
              return `<div class="stackcontent">${this.stack.slice(-8)}</div>`;
            }
          } else {
            return null;
          }
        },
        style: {
          fontSize: '9px',
        },
      },
    },
    legend: {
      enabled: false,
    },
    credits: {
      enabled: false,
    },

    tooltip: {
      // enabled: false,
      backgroundColor: 'none',
      borderWidth: 0,
      shadow: false,
      useHTML: true,
      padding: 0,
      // hideDelay: 6000,
      style: {
        pointerEvents: 'auto',
      },
      formatter: function () {
        // const classToApply = fleetSpecificRedirection()
        //   ? 'hiderightarrow'
        //   : 'toolcontent';

        if (chartType === 'VEH_STR_COL_TYPE') {
          if (
            this.series.name === 'Unknown' ||
            this.series.name === 'All thresholds met'
          ) {
            if (this.series.name === 'All thresholds met') {
              return `<div class="toolheader" id="sname">${this.series.name}  for ${this.y} hrs </div><div class=${'toolcontent'} id="sdate">Date : ${this.x} </div><div class=${'toolcontent'} id="pname" >Product: ${this.series.userOptions.stack} </div><div class="toolcontent hiderightarrow">Cycles used: ${this.point.cyu}</div>`;
            } else {
              return `<div class="toolheader" id="sname">${this.series.name} for ${this.y} hrs </div><div class=${'toolcontent'} id="sdate">Date : ${this.x} </div><div class=${'toolcontent'} id="pname" >Product: ${this.series.userOptions.stack} </div>`;
            }
          } else {
            return `<div class="toolheader">${this.series.name}  for ${
              this.y
            } hrs </div><div class=${'hiderightarrow'}>Date : ${
              this.x
            } </div><div class=${'toolcontent'} id="pname" >Product: ${
              this.series.userOptions.stack
            } </div><div class=${'toolcontent'} id="temp" >Temperature: ${
              this.point.tmp
            }% </div><div class=${'toolcontent'} id="soc">SOC: ${
              this.point.soc
            }% </div><div class=${'toolcontent'} id="crate">C-Rate: ${
              this.point.crt
            }% </div><div class=${'hiderightarrow'}>Cycle: ${
              this.point.cyl
            }%</div><div class=${'hiderightarrow'}>Cycles used: ${
              this.point.cyu
            }</div><div class=${'hiderightarrow'}>Cycles threshold: ${
              sessionStorage.getItem('selectedFleet').includes('GTW') === true
                ? 7.2098
                : 10.0
            }</div>`;
          }
        } else {
          return `<div class="toolheader" id="rname">${this.series.name} for ${this.y} hrs </div><div class=${'toolcontent'} id="rdate">Date : ${this.x} </div><div class=${'toolcontent'} id="rpname" >Product: ${this.series.userOptions.stack} </div>`;
        }
      },
      positioner: function (labelWidth, _, point) {
        const sName =
          this.chart.series.length > 2
            ? this.chart.series[1].name
            : this.chart.series[0].name;

        const sState =
          this.chart.series.length > 2 ? this.chart.series[1].state : 'hover';
        return {
          x:
            point.plotX - labelWidth + 10 > 0
              ? point.plotX - labelWidth + 40
              : point.plotX + 60,
          y:
            point.plotY > 0
              ? chartType === 'VEH_STR_COL_TYPE' &&
                sName === 'One or more thresholds exceeded' &&
                sState !== 'inactive' &&
                point.plotY > 150
                ? point.plotY - 100
                : point.plotY
              : 0,
        };
      },
    },
    plotOptions: {
      column: {
        stickyTracking: false,
        // enableMouseTracking: false,
        pointWidth: 10,
        pointPadding: 0,
        groupPadding: 0.2,
        stacking: 'normal',
        cursor: 'pointer',
        allowPointSelect: true,
        states: {
          select: { color: null, borderWidth: 0, borderColor: null },
          inactive: {
            opacity: 1,
          },
        },
        dataLabels: {
          enabled: false,
        },

        point: {
          events: {
            click: function (e) {
              if (chartType === 'VEH_REL_COL_TYPE') {
                if (clickedPointVehicle === this) {
                  this.series.chart.tooltip.hide(true);
                  clickedPointVehicle = null;
                } else {
                  this.series.chart.tooltip.refresh(this, e, true);
                  clickedPointVehicle = this;
                }
              } else if (chartType === 'VEH_STR_COL_TYPE') {
                if (clickedPointVehstr === this) {
                  this.series.chart.tooltip.hide(true);
                  clickedPointVehstr = null;
                } else {
                  this.series.chart.tooltip.refresh(this, e, true);
                  clickedPointVehstr = this;
                }
              }
            },
            select: function () {
              this.series.chart.update({
                tooltip: {
                  enabled: true,
                },
              });
            },
          },
        },
      },
    },
    series: seriesData.col,

    exporting: {
      buttons: {
        contextButton: {
          enabled: false,
          _id: 'custom_export',
          symbol: `url(${exportChart})`,
          symbolX: 25,
          symbolY: 40,
          align: 'right',
          verticalAlign: 'top',
          height: 28,
          width: 32,
          symbolSize: 22,
          y: 0,
          x: 10,
          menuItems: ['viewFullscreen', 'printChart'],
          theme: {
            states: {
              hover: {
                fill: 'transparent',
              },
              pressed: {
                fill: 'transparent',
              },
            },
          },
        },
      },
    },
  };

  return options;
}
