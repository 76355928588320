export const userConfig = () => {
  const entitlements = JSON.parse(
    sessionStorage.getItem('profile')
  ).entitlements;
  const trendSignals = entitlements
    .split(',')
    .filter(item => item.includes('ESS:Product-Trend'));
  const trendSignalNames = trendSignalFunc(trendSignals);

  return {
    group: JSON.parse(sessionStorage.getItem('profile')).groups.includes(
      'Default'
    ),
    role: false,
    entitlements: {
      product: {
        trendSignals: trendSignalNames,
      },
    },
  };
};

export const checkUserEntitlements = (entitlements, tabName, subTabName) => {
  const allEntitlements = JSON.parse(
    sessionStorage.getItem('profile')
  ).entitlements;
  const UserEntitlementArray = allEntitlements?.split(',');
  const isEntitlement = UserEntitlementArray?.find(ent =>
    ent.includes(entitlements)
  );
  const hasEntitlement = isEntitlement !== undefined;
  const urlParams = new URLSearchParams(window.location.search);
  const tabValueInUrl = urlParams.get('tb')
    ? urlParams.get('tb')
    : urlParams.get('vtb');
  const subTabValueInUrl = urlParams.get('stb')
    ? urlParams.get('stb')
    : urlParams.get('otb');
  if (
    (tabValueInUrl === tabName || subTabValueInUrl === subTabName) &&
    !hasEntitlement
  ) {
    window.location.replace('/access-denied');
  }
  return hasEntitlement;
};

export const trendSignalFunc = trendSignals => {
  const trendSignalNames = [];
  trendSignals.forEach(item => {
    switch (item) {
      case 'ESS:Product-Trend-Cellvoltage':
        trendSignalNames.push('Cell Voltage');
        break;
      case 'ESS:Product-Trend-Temperature':
        trendSignalNames.push('Temperature');
        break;
      case 'ESS:Product-Trend-Soc':
        trendSignalNames.push('State of Charge');
        break;
      case 'ESS:Product-Trend-Power':
        trendSignalNames.push('Power');
        break;
      case 'ESS:Product-Trend-Batteryvoltage':
        trendSignalNames.push('Battery Voltage');
        break;
      case 'ESS:Product-Trend-Batterycurrent':
        trendSignalNames.push('Battery Current');
        break;
      case 'ESS:Product-Trend-Batterypeccharge':
        trendSignalNames.push('Battery Peak Chg.');
        break;
      case 'ESS:Product-Trend-Batterypecdischarge':
        trendSignalNames.push('Battery Peak DisChg.');
        break;
      case 'ESS:Product-Trend-Batterycontcharge':
        trendSignalNames.push('Battery Cont. Chg.');
        break;
      case 'ESS:Product-Trend-Batterycontdischarge':
        trendSignalNames.push('Battery Cont. DisChg.');
        break;
      case 'ESS:Product-Trend-Batteryoverloadcntr':
        trendSignalNames.push('Battery Overload Cntr');
        break;
      case 'ESS:Product-Trend-Reliability':
        trendSignalNames.push('Reliability');
        break;
      case 'ESS:Product-Trend-Feedflow':
        trendSignalNames.push('Feed Flow');
        break;
      case 'ESS:Product-Trend-Overloadcounter':
        trendSignalNames.push('Overload Counter');
        break;
      case 'ESS:Product-Trend-Returnflow':
        trendSignalNames.push('Return Flow');
        break;
      case 'ESS:Product-Trend-Diagnosticevent':
        trendSignalNames.push('Diagnostic Event');
        break;
      case 'ESS:Product-Trend-Contactorstatus':
        trendSignalNames.push('Contactor Status');
        break;

      // for only production change - start
      case 'Traction-ESS:Product-Trend-Cellvoltage':
        trendSignalNames.push('Cell Voltage');
        break;
      case 'Traction-ESS:Product-Trend-Temperature':
        trendSignalNames.push('Temperature');
        break;
      case 'Traction-ESS:Product-Trend-Soc':
        trendSignalNames.push('State of Charge');
        break;
      case 'Traction-ESS:Product-Trend-Power':
        trendSignalNames.push('Power');
        break;
      case 'Traction-ESS:Product-Trend-Batteryvoltage':
        trendSignalNames.push('Battery Voltage');
        break;
      case 'Traction-ESS:Product-Trend-Batterycurrent':
        trendSignalNames.push('Battery Current');
        break;
      case 'Traction-ESS:Product-Trend-Batterypeccharge':
        trendSignalNames.push('Battery Peak Chg.');
        break;
      case 'Traction-ESS:Product-Trend-Batterypecdischarge':
        trendSignalNames.push('Battery Peak DisChg.');
        break;
      case 'Traction-ESS:Product-Trend-Batterycontcharge':
        trendSignalNames.push('Battery Cont. Chg.');
        break;
      case 'Traction-ESS:Product-Trend-Batterycontdischarge':
        trendSignalNames.push('Battery Cont. DisChg.');
        break;
      case 'Traction-ESS:Product-Trend-Batteryoverloadcntr':
        trendSignalNames.push('Battery Overload Cntr');
        break;
      case 'Traction-ESS:Product-Trend-Reliability':
        trendSignalNames.push('Reliability');
        break;
      case 'Traction-ESS:Product-Trend-Feedflow':
        trendSignalNames.push('Feed Flow');
        break;
      case 'Traction-ESS:Product-Trend-Overloadcounter':
        trendSignalNames.push('Overload Counter');
        break;
      case 'Traction-ESS:Product-Trend-Returnflow':
        trendSignalNames.push('Return Flow');
        break;
      case 'Traction-ESS:Product-Trend-Diagnosticevent':
        trendSignalNames.push('Diagnostic Event');
        break;
      case 'Traction-ESS:Product-Trend-Contactorstatus':
        trendSignalNames.push('Contactor Status');
        break;

      // for only production change - end
      default:
        break;
    }
  });
  return trendSignalNames;
};

export const getTimezone = date => {
  const check = date;
  const month = check.format('M');
  const summerTZ = ['4', '5', '6', '7', '8', '9', '10'];
  const summerMonth = summerTZ.includes(month);
  const TZ = summerMonth === true ? 'CEST' : 'CET';
  return TZ;
};

export const directlinktrendSignalCheck = trendSignal => {
  let trendSignalNames = false;
  const entitlements = JSON.parse(
    sessionStorage.getItem('profile')
  ).entitlements;

  switch (trendSignal) {
    case 'Cell Voltage':
      trendSignalNames =
        entitlements.includes('ESS:Product-Trend-Cellvoltage') === true
          ? true
          : entitlements.includes('Traction-ESS:Product-Trend-Cellvoltage');
      break;
    case 'Temperature':
      trendSignalNames =
        entitlements.includes('ESS:Product-Trend-Temperature') === true
          ? true
          : entitlements.includes('Traction-ESS:Product-Trend-Temperature');
      break;
    case 'State of Charge':
      trendSignalNames =
        entitlements.includes('ESS:Product-Trend-Soc') === true
          ? true
          : entitlements.includes('Traction-ESS:Product-Trend-Soc');
      break;
    case 'Power':
      trendSignalNames =
        entitlements.includes('ESS:Product-Trend-Power') === true
          ? true
          : entitlements.includes('Traction-ESS:Product-Trend-Power');
      break;
    case 'Battery Voltage':
      trendSignalNames =
        entitlements.includes('ESS:Product-Trend-Batteryvoltage') === true
          ? true
          : entitlements.includes('Traction-ESS:Product-Trend-Batteryvoltage');
      break;
    case 'Battery Current':
      trendSignalNames =
        entitlements.includes('ESS:Product-Trend-Batterycurrent') === true
          ? true
          : entitlements.includes('Traction-ESS:Product-Trend-Batterycurrent');
      break;
    case 'Battery Peak Chg.':
      trendSignalNames =
        entitlements.includes('ESS:Product-Trend-Batterypeccharge') === true
          ? true
          : entitlements.includes(
              'Traction-ESS:Product-Trend-Batterypeccharge'
            );
      break;
    case 'Battery Peak DisChg.':
      trendSignalNames =
        entitlements.includes('ESS:Product-Trend-Batterypecdischarge') === true
          ? true
          : entitlements.includes(
              'Traction-ESS:Product-Trend-Batterypecdischarge'
            );
      break;
    case 'Battery Cont. Chg.':
      trendSignalNames =
        entitlements.includes('ESS:Product-Trend-Batterycontcharge') === true
          ? true
          : entitlements.includes(
              'Traction-ESS:Product-Trend-Batterycontcharge'
            );
      break;
    case 'Battery Cont. DisChg.':
      trendSignalNames =
        entitlements.includes('ESS:Product-Trend-Batterycontdischarge') === true
          ? true
          : entitlements.includes(
              'Traction-ESS:Product-Trend-Batterycontdischarge'
            );
      break;
    case 'Battery Overload Cntr':
      trendSignalNames =
        entitlements.includes('ESS:Product-Trend-Batteryoverloadcntr') === true
          ? true
          : entitlements.includes(
              'Traction-ESS:Product-Trend-Batteryoverloadcntr'
            );
      break;
    case 'Reliability':
      trendSignalNames =
        entitlements.includes('ESS:Product-Trend-Reliability') === true
          ? true
          : entitlements.includes('Traction-ESS:Product-Trend-Reliability');
      break;
    case 'Feed Flow':
      trendSignalNames =
        entitlements.includes('ESS:Product-Trend-Feedflow') === true
          ? true
          : entitlements.includes('Traction-ESS:Product-Trend-Feedflow');
      break;
    case 'Overload Counter':
      trendSignalNames =
        entitlements.includes('ESS:Product-Trend-Overloadcounter') === true
          ? true
          : entitlements.includes('Traction-ESS:Product-Trend-Overloadcounter');
      break;
    case 'Return Flow':
      trendSignalNames =
        entitlements.includes('ESS:Product-Trend-Returnflow') === true
          ? true
          : entitlements.includes('Traction-ESS:Product-Trend-Returnflow');
      break;
    case 'Diagnostic Event':
      trendSignalNames =
        entitlements.includes('ESS:Product-Trend-Diagnosticevent') === true
          ? true
          : entitlements.includes('Traction-ESS:Product-Trend-Diagnosticevent');
      break;

    default:
      break;
  }

  return trendSignalNames;
};
