import React, { useEffect, useRef, useState } from 'react';
import { azureMapsKey } from './constants';

function MapSearch({ onPlaceSelected, defaultPlace }) {
  const searchInput = useRef(null);
  const [search, setSearch] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [showResults, setShowResults] = useState(false);

  useEffect(() => {
    if (search && search.length > 1) {
      // fetch data from azure maps api
      const url = `https://atlas.microsoft.com/search/address/json?api-version=1.0&query=${search}&subscription-key=${azureMapsKey}&limit=5`;
      fetch(url)
        .then(response => response.json())
        .then(data => {
          setSearchResults(data.results);
        });
    }
  }, [search]);
  useEffect(() => {
    if (defaultPlace !== null) {
      setSearch(defaultPlace.formatted_address);
    }
  }, [defaultPlace]);

  // onclick of outside hide the results
  useEffect(() => {
    const handleClick = e => {
      if (e.target.name !== 'mapSearchInput') {
        setShowResults(false);
      }
    };
    document.addEventListener('click', handleClick);
    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, []);

  const handleSelect = result => {
    setShowResults(false);
    setSearch(result.address.freeformAddress);
    console.log(result);
    onPlaceSelected({
      formatted_address: result.address.freeformAddress,
      geometry: {
        location: {
          lat: result.position.lat,
          lng: result.position.lon,
        },
      },
    });
  };

  return (
    <div>
      <input
        ref={searchInput}
        autoComplete="off"
        style={{
          width: '100%',
          height: '32px',
          borderRadius: '0px',
          border: '1px solid #dee2e6',
          padding: '8px',
        }}
        name="mapSearchInput"
        type="text"
        onClick={() => {
          setSearch('');
          setShowResults(true);
        }}
        onChange={e => setSearch(e.target.value)}
        value={search}
      />
      <div
        style={{
          position: 'absolute',
          zIndex: '99',
          backgroundColor: 'white',
          width: `${searchInput.current?.clientWidth}px`,
          boxShadow: '0 0 5px 0 rgba(0, 0, 0, 0.2)',
        }}>
        {showResults &&
          searchResults.length > 0 &&
          searchResults.map(result => (
            <div
              style={{
                padding: '10px',
                cursor: 'pointer',
                borderBottom: '1px solid #f1f1f1',
              }}
              key={result.id}
              onClick={() => handleSelect(result)}>
              {result.address.freeformAddress}
            </div>
          ))}
      </div>
    </div>
  );
}

export default MapSearch;
