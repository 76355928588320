import React, { Component } from 'react';
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import './styles.scss';

import {
  AvailGreenIcon,
  AvailRedIcon,
  AvailableUnknownIcon,
  BusIcon,
  Converter_d,
  FilterActiveIcon,
  FilterIcon,
  ProductBattery,
  ReliableGreenIcon,
  ReliableRedIcon,
  ReliableUnknownStatusIcon,
  SideMenuResetIcon,
  StressGreenIcon,
  StressRedIcon,
  StressUnknownStatusIcon,
  UnmountedIcon,
  VehicleIcon,
  WarningInfoIcon,
  WarningRedDotIcon,
} from '../../assets/images';
import {
  hasSearchValueProduct,
  hasSearchValueVehicle,
  searchItemInVehicleProduct,
} from '../../utils/search_utils';
import {
  highlightSearchTerm,
  refactorBatteryText,
  refactorPackText,
  refactormidscreenText,
  truncateAccText,
} from '../../utils/textRefactor';
import SearchBox from '../SearchBox';
import FleetList from '../SideMenuItems';

class SideMenu extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      itemsToShow: 1,
      expanded: false,
      filterExpand: true,
      selectedVehicle: '',
      isExpanded: false,
      checkedRadioEvent: null,
    };
  }

  componentDidMount() {
    window.$handleFilterDropdownChange = this.props.handleFilterDropdownChange;
    window.$onGoodFilterCkick = this.props.onGoodFilterCkick;
    window.$onPoorFilterCkick = this.props.onPoorFilterCkick;
    window.$onUnknownFilterCkick = this.props.onUnknownFilterCkick;
  }

  UNSAFE_componentWillReceiveProps(nextProps, prevState) {
    window.$listSelection = nextProps.listSelection;
  }

  toggleFleetList = () => {
    this.setState({ expanded: !this.state.expanded });

    if (sessionStorage.getItem('fleetToggle') === 'true') {
      sessionStorage.setItem('fleetToggle', 'false');
    } else {
      sessionStorage.setItem('fleetToggle', 'true');
    }
  };

  toggleFilter = () => {
    this.setState({ filterExpand: !this.state.filterExpand });
  };

  filterCheck = (item, index, list_sel = false) => {
    if (
      this.props.filterAvailability === '' &&
      this.props.filterReliability === '' &&
      this.props.filterUtilization === ''
    ) {
      return true;
    }

    let returnValue = false;
    if (list_sel) {
      item.vdat.forEach((plist, index) => {
        if (this.props.filterAvailability === 'red')
          if (plist.a === 'Not available') {
            returnValue = true;
          }

        if (this.props.filterAvailability === 'green')
          if (plist.a === 'Available') {
            returnValue = true;
          }

        if (this.props.filterAvailability === 'unknown')
          if (plist.a === 'Unknown') {
            returnValue = true;
          }

        if (this.props.filterReliability === 'red')
          if (plist.r === 'Not reliable') {
            returnValue = true;
          }

        if (this.props.filterReliability === 'green')
          if (plist.r === 'Reliable') {
            returnValue = true;
          }

        if (this.props.filterReliability === 'unknown')
          if (plist.r === 'Unknown') {
            returnValue = true;
          }

        if (this.props.filterUtilization === 'red')
          if (plist.s === 'Above normal') {
            returnValue = true;
          }

        if (this.props.filterUtilization === 'green')
          if (plist.s === 'Normal') {
            returnValue = true;
          }

        if (this.props.filterUtilization === 'unknown')
          if (plist.s === 'Unknown') {
            returnValue = true;
          }
      });
    } else {
      if (this.props.filterAvailability === 'red')
        if (item.a === 'Not available') {
          returnValue = true;
        }

      if (this.props.filterAvailability === 'green')
        if (item.a === 'Available') {
          returnValue = true;
        }

      if (this.props.filterAvailability === 'unknown')
        if (item.a === 'Unknown') {
          returnValue = true;
        }

      if (this.props.filterReliability === 'red')
        if (item.r === 'Not reliable') {
          returnValue = true;
        }

      if (this.props.filterReliability === 'green')
        if (item.r === 'Reliable') {
          returnValue = true;
        }

      if (this.props.filterReliability === 'unknown')
        if (item.r === 'Unknown') {
          returnValue = true;
        }

      if (this.props.filterUtilization === 'red')
        if (item.s === 'Above normal') {
          returnValue = true;
        }

      if (this.props.filterUtilization === 'green')
        if (item.s === 'Normal') {
          returnValue = true;
        }

      if (this.props.filterUtilization === 'unknown')
        if (item.s === 'Unknown') {
          returnValue = true;
        }
    }

    return returnValue;
  };

  filterCheck_old = (item, index) => {
    if (
      this.props.selectedSearchKey === 'Select an index' ||
      (this.props.isGoodSlected === false &&
        this.props.isPoorSelected === false &&
        this.props.isUnknownSelected === false) ||
      (this.props.isGoodSlected === true &&
        this.props.isPoorSelected === true &&
        this.props.isUnknownSelected === true)
    ) {
      return true;
    }
    let returnValue = false;
    if (this.props.isGoodSlected === true) {
      if (this.props.selectedSearchKey === 'Availability')
        if (item.a === 'Available') {
          returnValue = true;
        }

      if (this.props.selectedSearchKey === 'Reliability')
        if (item.r === 'Reliable') {
          returnValue = true;
        }

      if (this.props.selectedSearchKey === 'Utilization')
        if (item.s === 'Normal') {
          returnValue = true;
        }
    }

    if (this.props.isPoorSelected === true) {
      if (this.props.selectedSearchKey === 'Availability')
        if (item.a === 'Not available') {
          returnValue = true;
        }

      if (this.props.selectedSearchKey === 'Reliability')
        if (item.r === 'Not reliable') {
          returnValue = true;
        }

      if (this.props.selectedSearchKey === 'Utilization')
        if (item.s === 'Above normal') {
          returnValue = true;
        }
    }

    if (this.props.isUnknownSelected === true) {
      if (this.props.selectedSearchKey === 'Availability')
        if (item.a === 'Unknown') {
          returnValue = true;
        }

      if (this.props.selectedSearchKey === 'Reliability')
        if (item.r === 'Unknown') {
          returnValue = true;
        }

      if (this.props.selectedSearchKey === 'Utilization')
        if (item.s === 'Unknown') {
          returnValue = true;
        }
    }

    return returnValue;
  };

  filterCount = () => {
    let count = 0;
    let productCount = 0;

    if (this.props.vehicles != null) {
      this.props.vehicles[0].vst.map(item =>
        item.vdat.forEach((plist, index) => {
          if (hasSearchValueProduct(plist, this.props.searchQuery)) {
            productCount++;
            this.filterCheck(plist, index) && count++;
          }
        })
      );
      return count > 1
        ? count + '/' + productCount + ' products'
        : count + '/' + productCount + ' product';
    }
  };

  refactortext = str => {
    return str.replace(/^[^\d]*/, match => {
      return match.substring(0, 4) + '...';
    });
  };

  render() {
    const { searchQuery } = this.props;

    return (
      <div className="containersidemenu">
        <div data-intro="fleet-list-toggle" className="containerfleetlist">
          <FleetList
            // onTrainSelect={this.props.onTrainSelect}
            // trainno={this.props.trainno}
            FleetList={this.props.fleetList}
            toggleFleetList={this.toggleFleetList}
            fleetExpanded={this.state.expanded}
            itemsToShow={this.state.itemsToShow}
            // onFleetListClick={this.onFleetListClick}
            onFleetListClick={this.props.onFleetListClick}
            selectedFleet={this.props.selectedFleet}
            selectedFleetType={this.props.selectedFleetType}
            selectedView={this.props.selectedView}
            selectedSite={sessionStorage.getItem('selectedSite')}
          />
        </div>
        <hr />
        <div>
          <div className="frmsection">
            <Form
              noValidate
              onSubmit={event => {
                event.preventDefault();
              }}>
              <Row>
                <SearchBox
                  query={this.props.searchQuery}
                  handleSearchInputChange={this.props.handleSearchInputChange}
                  enableSearch={this.props.vehicles === null}
                />
                <Form.Group as={Col} sm={2}>
                  <Button
                    variant="outline-light"
                    className="filterbtn"
                    onClick={() => this.toggleFilter()}>
                    {this.props.filterAvailability !== '' ||
                    this.props.filterReliability !== '' ||
                    this.props.filterUtilization !== '' ? (
                      <FilterActiveIcon />
                    ) : (
                      <FilterIcon />
                    )}
                  </Button>
                </Form.Group>
              </Row>
            </Form>
          </div>
        </div>
        <div className="containersearch" data-intro="filters">
          <div>
            Showing{' '}
            <span className="chkbox">
              <input
                name="vehicle"
                type="checkbox"
                // checked={true}
                checked={
                  !!(
                    this.props.listSelection === 'vehicle' ||
                    this.props.listSelection === 'vehicleproduct'
                  )
                }
                onChange={e => {
                  this.props.onListSelectionClick(e, 'vehicle');
                }}
              />{' '}
              Vehicles{'  '}
              <input
                name="product"
                type="checkbox"
                // checked={true}
                checked={
                  !!(
                    this.props.listSelection === 'product' ||
                    this.props.listSelection === 'vehicleproduct'
                  )
                }
                onChange={e => {
                  this.props.onListSelectionClick(e, 'product');
                }}
              />{' '}
              Products
            </span>
          </div>
          <hr />
          {this.state.filterExpand && (
            <Row className="listfilter noLeftpadding noRightpadding">
              <Col md={12} className="titlerow">
                <span className="filter">Filter</span>

                <button
                  className="reset"
                  onClick={() => {
                    this.props.sideMenuFilter('reset', '');
                  }}>
                  <SideMenuResetIcon className="reseticon" />
                  Reset
                </button>
              </Col>
              <Col md={12} className="filterrow">
                <span className="filtertext">Availability</span>
                <span className="filtericons">
                  <button
                    className={
                      this.props.filterAvailability === 'red'
                        ? 'icon activefilter'
                        : 'icon'
                    }
                    onClick={() => {
                      this.props.sideMenuFilter(
                        'availability',
                        this.props.filterAvailability === 'red' ? '' : 'red'
                      );
                    }}>
                    <AvailRedIcon />
                  </button>
                  <button
                    className={
                      this.props.filterAvailability === 'green'
                        ? 'icon activefilter'
                        : 'icon'
                    }
                    onClick={() => {
                      this.props.sideMenuFilter(
                        'availability',
                        this.props.filterAvailability === 'green' ? '' : 'green'
                      );
                    }}>
                    <AvailGreenIcon />
                  </button>
                  <button
                    className={
                      this.props.filterAvailability === 'unknown'
                        ? 'icon activefilter'
                        : 'icon'
                    }
                    onClick={() => {
                      this.props.sideMenuFilter(
                        'availability',
                        this.props.filterAvailability === 'unknown'
                          ? ''
                          : 'unknown'
                      );
                    }}>
                    <AvailableUnknownIcon />
                  </button>
                </span>
              </Col>
              <Col md={12} className="filterrow">
                <span className="filtertext">Reliability</span>
                <span className="filtericons">
                  <button
                    className={
                      this.props.filterReliability === 'red'
                        ? 'icon activefilter'
                        : 'icon'
                    }
                    onClick={() => {
                      this.props.sideMenuFilter(
                        'reliability',
                        this.props.filterReliability === 'red' ? '' : 'red'
                      );
                    }}>
                    <ReliableRedIcon />
                  </button>
                  <button
                    className={
                      this.props.filterReliability === 'green'
                        ? 'icon activefilter'
                        : 'icon'
                    }
                    onClick={() => {
                      this.props.sideMenuFilter(
                        'reliability',
                        this.props.filterReliability === 'green' ? '' : 'green'
                      );
                    }}>
                    <ReliableGreenIcon />
                  </button>
                  <button
                    className={
                      this.props.filterReliability === 'unknown'
                        ? 'icon activefilter'
                        : 'icon'
                    }
                    onClick={() => {
                      this.props.sideMenuFilter(
                        'reliability',
                        this.props.filterReliability === 'unknown'
                          ? ''
                          : 'unknown'
                      );
                    }}>
                    <ReliableUnknownStatusIcon />
                  </button>
                </span>
              </Col>
              <Col md={12} className="filterrow">
                <span className="filtertext">Utilization</span>
                <span className="filtericons">
                  <button
                    className={
                      this.props.filterUtilization === 'red'
                        ? 'icon activefilter'
                        : 'icon'
                    }
                    onClick={() => {
                      this.props.sideMenuFilter(
                        'utilization',
                        this.props.filterUtilization === 'red' ? '' : 'red'
                      );
                    }}>
                    <StressRedIcon />
                  </button>
                  <button
                    className={
                      this.props.filterUtilization === 'green'
                        ? 'icon activefilter'
                        : 'icon'
                    }
                    onClick={() => {
                      this.props.sideMenuFilter(
                        'utilization',
                        this.props.filterUtilization === 'green' ? '' : 'green'
                      );
                    }}>
                    <StressGreenIcon />
                  </button>
                  <button
                    className={
                      this.props.filterUtilization === 'unknown'
                        ? 'icon activefilter'
                        : 'icon'
                    }
                    onClick={() => {
                      this.props.sideMenuFilter(
                        'utilization',
                        this.props.filterUtilization === 'unknown'
                          ? ''
                          : 'unknown'
                      );
                    }}>
                    <StressUnknownStatusIcon />
                  </button>
                </span>
              </Col>
            </Row>
          )}
        </div>
        {/* <hr /> */}
        <div className="totalvehicles">
          {this.props.vehicles != null && this.filterCount()}
        </div>
        {this.props.listSelection === 'vehicle' &&
          (this.props.vehicles !== null ? (
            <div className="containervehiclelist">
              <Accordion
                id="accordion1"
                allowZeroExpanded
                preExpanded={
                  sessionStorage.getItem('unmountedESSclicked') !== 'true'
                    ? [sessionStorage.getItem('selectedVehicle')]
                    : ['']
                }>
                {this.props.vehicles != null &&
                  this.props.vehicles[0].vst.map(
                    (item, index) =>
                      this.filterCheck(item, index) &&
                      hasSearchValueVehicle(item, searchQuery) && (
                        <AccordionItem
                          key={index}
                          uuid={item.vn}
                          onClick={() => {
                            this.setState({ selectedVehicle: item.vn });
                          }}>
                          <AccordionItemHeading
                            className={
                              this.state.selectedVehicle === item.vn &&
                              sessionStorage.getItem('unmountedESSclicked') !==
                                'true'
                                ? 'active-site'
                                : ''
                            }
                            onClick={() => {
                              sessionStorage.setItem(
                                'unmountedESSclicked',
                                'false'
                              );
                              this.props.onVehicleListClick(item.vn);
                            }}>
                            <AccordionItemButton>
                              <span>
                                <span className="vehicleicon">
                                  {this.props.selectedFleetType === 'eBus' ? (
                                    <BusIcon />
                                  ) : (
                                    <VehicleIcon />
                                  )}
                                </span>
                                {this.props.size < 300 && this.props.size > 150
                                  ? highlightSearchTerm(
                                      refactorBatteryText(item.dvn),
                                      searchQuery
                                    )
                                  : highlightSearchTerm(
                                      truncateAccText(item.dvn),
                                      searchQuery
                                    )}
                                <span className="vehiclestatusicon">
                                  {this.state.selectedVehicle !== item.vn &&
                                  item.rbc > 0 ? ( //  item.ct === undefined ? (
                                    <span>
                                      <WarningInfoIcon />
                                      <span>{'+' + item.rbc}</span>
                                    </span>
                                  ) : (
                                    ''
                                  )}
                                  {this.state.selectedVehicle === item.vn &&
                                  item.rbc > 0 ? ( //  item.ct === undefined ? (
                                    <span>
                                      <WarningRedDotIcon />
                                    </span>
                                  ) : (
                                    ''
                                  )}
                                </span>
                              </span>
                            </AccordionItemButton>
                          </AccordionItemHeading>
                        </AccordionItem>
                      )
                  )}
              </Accordion>
            </div>
          ) : (
            <div className="containervehiclelist">
              <span className="loading">Loading ...</span>
            </div>
          ))}
        {this.props.listSelection === 'product' &&
          (this.props.vehicles !== null ? (
            <div className="containerproductlist">
              <Accordion
                id="accordion1"
                allowZeroExpanded
                preExpanded={
                  sessionStorage.getItem('unmountedESSclicked') !== 'true'
                    ? [sessionStorage.getItem('selectedVehicle')]
                    : ['']
                }>
                {this.props.vehicles != null &&
                  this.props.vehicles[0].vst.map(item =>
                    item.vdat.map(
                      (plist, index) =>
                        this.filterCheck(plist, index) &&
                        hasSearchValueProduct(plist, searchQuery) &&
                        (plist.type === 'converter' &&
                        (this.props.filterAvailability !== '' ||
                          this.props.filterReliability !== '' ||
                          this.props.filterUtilization !== '') ? (
                          ''
                        ) : (
                          <AccordionItem uuid={plist.dn} key={index}>
                            <AccordionItemHeading
                              onClick={() => {
                                sessionStorage.setItem(
                                  'unmountedESSclicked',
                                  'false'
                                );
                                this.props.onVehicleProductListClick(
                                  item.vn,
                                  plist.an,
                                  plist.ip,
                                  plist.dn,
                                  plist.bpu,
                                  plist.type
                                );
                              }}
                              className={
                                sessionStorage.getItem('selectedProductIP') ===
                                  plist.ip &&
                                sessionStorage.getItem(
                                  'selectedVehicleProduct'
                                ) === plist.an
                                  ? 'active-site'
                                  : ''
                              }>
                              <AccordionItemButton>
                                <span className="productlisticon">
                                  {plist.type === 'converter' ? (
                                    <Converter_d />
                                  ) : (
                                    <ProductBattery />
                                  )}
                                </span>
                                {this.props.size < 300
                                  ? highlightSearchTerm(
                                      plist.dn.replace(/_(.*?)_/, '_...'),
                                      searchQuery
                                    )
                                  : highlightSearchTerm(plist.dn, searchQuery)}
                                {plist.type !== 'converter' && (
                                  <span className="productstatusicon">
                                    {plist.a === 'Available' ? (
                                      <AvailGreenIcon className="statusicon" />
                                    ) : plist.a === 'Not available' ? (
                                      <AvailRedIcon className="statusicon" />
                                    ) : (
                                      <AvailableUnknownIcon className="statusicon" />
                                    )}

                                    {plist.r === 'Reliable' ? (
                                      <ReliableGreenIcon className="statusicon" />
                                    ) : plist.r === 'Not reliable' ? (
                                      <ReliableRedIcon className="statusicon" />
                                    ) : (
                                      <ReliableUnknownStatusIcon className="statusicon" />
                                    )}

                                    {plist.s === 'Above normal' ? (
                                      <StressRedIcon className="statusicon" />
                                    ) : plist.s === 'Normal' ? (
                                      <StressGreenIcon className="statusicon" />
                                    ) : (
                                      <StressUnknownStatusIcon className="statusicon" />
                                    )}
                                  </span>
                                )}
                              </AccordionItemButton>
                            </AccordionItemHeading>
                          </AccordionItem>
                        ))
                    )
                  )}
              </Accordion>
            </div>
          ) : (
            <div className="containerproductlist">
              <span className="loading">Loading ...</span>
            </div>
          ))}
        {this.props.listSelection === 'vehicleproduct' &&
          (this.props.vehicles !== null ? (
            <div
              data-intro="vehicle_product_list"
              className="containervehicleproductlist dxc"
              id="scrollvplist">
              <Accordion
                id="accordion1"
                allowZeroExpanded
                preExpanded={
                  sessionStorage.getItem('unmountedESSclicked') !== 'true'
                    ? [sessionStorage.getItem('selectedVehicle')]
                    : ['']
                }>
                {this.props.vehicles != null &&
                  this.props.vehicles[0].vst.map(
                    (item, index) =>
                      this.filterCheck(item, index, true) &&
                      searchItemInVehicleProduct(item, searchQuery) && (
                        <AccordionItem
                          key={index}
                          uuid={item.vn}
                          onClick={() => {
                            this.setState({ selectedVehicle: item.vn });
                          }}
                          {...(searchQuery && {
                            dangerouslySetExpanded: true,
                          })}>
                          <AccordionItemHeading
                            data-intro={`vehicle_num_${index}`}
                            className={
                              sessionStorage.getItem('selectedVehicle') ===
                                item.vn &&
                              sessionStorage.getItem('unmountedESSclicked') !==
                                'true'
                                ? 'active-site'
                                : ''
                            }>
                            <AccordionItemButton>
                              <span
                                data-intro={`vehicle_num_click_${index}`}
                                className="headingcontainer"
                                onClick={() => {
                                  sessionStorage.setItem(
                                    'unmountedESSclicked',
                                    'false'
                                  );
                                  this.props.onVehicleListClick(item.vn);
                                }}>
                                <span className="vehicleicon">
                                  {this.props.selectedFleetType === 'eBus' ? (
                                    <BusIcon />
                                  ) : (
                                    <VehicleIcon />
                                  )}
                                </span>
                                <span className="vehicletext">
                                  {this.props.size < 300
                                    ? highlightSearchTerm(
                                        refactorPackText(item.dvn),
                                        searchQuery
                                      )
                                    : highlightSearchTerm(
                                        truncateAccText(item.dvn),
                                        searchQuery
                                      )}
                                </span>
                                <div className="vehiclestatusicon">
                                  {this.state.selectedVehicle !== item.vn &&
                                  item.rbc > 0 ? ( //  item.ct === undefined ? (
                                    <span>
                                      <WarningInfoIcon />
                                      <span>{'+' + item.rbc}</span>
                                    </span>
                                  ) : (
                                    ''
                                  )}
                                  {this.state.selectedVehicle === item.vn &&
                                  item.rbc > 0 ? ( //  item.ct === undefined ? (
                                    <span>
                                      <WarningRedDotIcon />
                                    </span>
                                  ) : (
                                    ''
                                  )}
                                </div>
                              </span>
                            </AccordionItemButton>
                          </AccordionItemHeading>
                          {item.vdat.map((plist, sindex) =>
                            plist.type === 'converter' &&
                            (this.props.filterAvailability !== '' ||
                              this.props.filterReliability !== '' ||
                              this.props.filterUtilization !== '') ? (
                              ''
                            ) : (
                              <AccordionItemPanel
                                key={sindex}
                                data-intro={`vehicle_product_${index}${sindex}`}
                                onClick={() => {
                                  sessionStorage.setItem(
                                    'unmountedESSclicked',
                                    'false'
                                  );
                                  this.props.onVehicleProductListClick(
                                    item.vn,
                                    plist.an,
                                    plist.ip,
                                    plist.dn,
                                    plist.bpu,
                                    plist.type
                                  );
                                }}
                                className={
                                  sessionStorage.getItem(
                                    'selectedProductIP'
                                  ) === plist.ip &&
                                  sessionStorage.getItem(
                                    'selectedVehicleProduct'
                                  ) === plist.an
                                    ? 'accordion__panel active-product'
                                    : 'accordion__panel'
                                }>
                                <span className="productlisticon">
                                  {plist.type === 'converter' ? (
                                    <Converter_d />
                                  ) : (
                                    <ProductBattery />
                                  )}
                                </span>
                                {plist.type === 'converter' ? (
                                  <span className="producttext">
                                    {highlightSearchTerm(plist.dn, searchQuery)}
                                  </span>
                                ) : (
                                  <span className="producttext">
                                    {this.props.size < 300 &&
                                    this.props.size > 150
                                      ? highlightSearchTerm(
                                          refactormidscreenText(plist.dn),
                                          searchQuery
                                        )
                                      : highlightSearchTerm(
                                          refactorBatteryText(plist.dn),
                                          searchQuery
                                        )}
                                  </span>
                                )}
                                {plist.type !== 'converter' && (
                                  <span className="productstatusicon">
                                    {plist.a === 'Available' ? (
                                      <AvailGreenIcon className="statusicon" />
                                    ) : plist.a === 'Not available' ? (
                                      <AvailRedIcon className="statusicon" />
                                    ) : (
                                      <AvailableUnknownIcon className="statusicon" />
                                    )}

                                    {plist.r === 'Reliable' ? (
                                      <ReliableGreenIcon className="statusicon" />
                                    ) : plist.r === 'Not reliable' ? (
                                      <ReliableRedIcon className="statusicon" />
                                    ) : (
                                      <ReliableUnknownStatusIcon className="statusicon" />
                                    )}

                                    {plist.s === 'Above normal' ? (
                                      <StressRedIcon className="statusicon" />
                                    ) : plist.s === 'Normal' ? (
                                      <StressGreenIcon className="statusicon" />
                                    ) : (
                                      <StressUnknownStatusIcon className="statusicon" />
                                    )}
                                  </span>
                                )}
                              </AccordionItemPanel>
                            )
                          )}
                        </AccordionItem>
                      )
                  )}
              </Accordion>
            </div>
          ) : (
            <div className="containervehicleproductlist">
              <span className="loading">Loading ...</span>
            </div>
          ))}
        {/* Unmounted ESS starts from here */}
        {sessionStorage.getItem('selectedFleet') !== 'ABB ESS Demo Fleet' && (
          <div className="unmountedesscon">
            <div className="totalvehicles">
              {this.props.unmountedESSListData != null &&
                this.props.unmountedESSListData.length + ' ' + 'unmounted ESS'}
            </div>
            <div className="unmounted-ess containervehicleproductlist">
              {this.props.loadingUnmountedESSList === true ||
              this.props.unmountedESSListData === null ? (
                'Loading ...'
              ) : this.props.unmountedESSListData.length > 0 ? (
                <Accordion
                  id="accordion2"
                  allowZeroExpanded
                  preExpanded={['Unmounted_ESS']}>
                  <AccordionItem uuid="Unmounted_ESS">
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        <span className="headingcontainer">
                          <span className="vehicleicon">
                            <VehicleIcon />
                          </span>
                          Unmounted ESS
                        </span>
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <div className="unmountedlist">
                      {this.props.unmountedESSListData.map((item, index) => (
                        <AccordionItemPanel
                          key={item + index}
                          onClick={e => {
                            sessionStorage.setItem(
                              'unmountedESSclicked',
                              'true'
                            );
                            this.props.onVehicleProductListClick(
                              item.vn,
                              item.an,
                              item.ip,
                              item.dn,
                              item.bpu
                            );
                          }}
                          className={
                            sessionStorage.getItem('selectedProductIP') ===
                              item.ip &&
                            sessionStorage.getItem('selectedVehicleProduct') ===
                              item.an
                              ? 'accordion__panel active-product'
                              : 'accordion__panel'
                          }>
                          <span className="productlisticon">
                            <ProductBattery />
                          </span>
                          {item.dn}
                        </AccordionItemPanel>
                      ))}
                    </div>
                  </AccordionItem>
                </Accordion>
              ) : this.props.unmountedESSListData.length === 0 ? (
                <Accordion
                  id="accordion2"
                  allowZeroExpanded
                  preExpanded={['Unmounted_ESS']}>
                  <AccordionItem uuid="Unmounted_ESS">
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        <span className="headingcontainer">
                          <span className="vehicleicon">
                            <UnmountedIcon />
                          </span>
                          Unmounted ESS
                        </span>
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel
                      className={'accordion__panel unmounted_nodata '}>
                      <div> No unmounted ESS registered.</div>
                    </AccordionItemPanel>
                  </AccordionItem>
                </Accordion>
              ) : (
                ''
              )}
            </div>
          </div>
        )}
        <hr />
      </div>
    );
  }
}

export default withRouter(SideMenu);
