import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import { RightDirectionArrow } from '../../assets/images';
import { months } from './constants';

/**
 * Renders a MonthPicker component.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {function} props.settoggle - The function to toggle the MonthPicker component.
 * @param {function} props.setselectedMonthYear - The function to set the selected month and year.
 * @param {Array} props.selectedMonthYear - The array containing the selected month and year.
 * @returns {JSX.Element} The rendered MonthPicker component.
 */
export function MonthPicker({
  settoggle,
  setselectedMonthYear,
  selectedMonthYear,
}) {
  const style = {
    header: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '160px',
      padding: '4px',
    },
    container: {
      display: 'grid',
      gridTemplateColumns: 'auto auto auto',
      width: '160px',
    },
    month: {
      padding: '4px',
      textAlign: 'center',
      cursor: 'pointer',
      fontSize: '12px',
      lineHeight: '16px',
      fontWeight: '400',
    },
  };
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
  const year = new Date().getFullYear();
  const [clickCount, setClickCount] = useState(0);
  const handleMonthChange = (month, year) => {
    const monthyears = selectedMonthYear;
    if (clickCount === 0) {
      monthyears[0].month = month;
      monthyears[0].year = year;
      monthyears[1].month = month;
      monthyears[1].year = year;
      setselectedMonthYear([...monthyears]);
      setClickCount(clickCount + 1);
    }
    if (clickCount === 1) {
      // compare if the selected month and year greater than the previous selected month and year
      monthyears[1].month = month;
      monthyears[1].year = year;
      setselectedMonthYear([...monthyears]);
      if (
        new Date(`${monthyears[0].year} ${monthyears[0].month}`) >
        new Date(`${year} ${month}`)
      ) {
        // swap the selected month and year with previous
        monthyears[1].month = monthyears[0].month;
        monthyears[1].year = monthyears[0].year;
        monthyears[0].month = month;
        monthyears[0].year = year;
        setselectedMonthYear([...monthyears]);
      }
      setClickCount(0);
    }
  };

  return (
    <div>
      <div style={style.header}>
        <Button
          variant="none"
          className="border-0"
          onClick={() => setCurrentYear(currentYear - 1)}
          style={style.arrows}>
          <RightDirectionArrow style={{ transform: 'rotate(180deg)' }} />
        </Button>
        <div
          style={{
            fontWeight: '500',
            fontSize: '14px',
            lineHeight: '16px',
            padding: '8px',
          }}>
          {currentYear}
        </div>
        <Button
          className="border-0"
          variant="none"
          disabled={currentYear === year}
          onClick={() => setCurrentYear(currentYear + 1)}
          style={style.arrows}>
          <RightDirectionArrow />
        </Button>
      </div>

      <div style={style.container}>
        {months.map((month, idx) => (
          <div
            key={idx}
            style={{
              ...style.month,
              backgroundColor:
                (selectedMonthYear[0].month === month &&
                  selectedMonthYear[0].year === currentYear) ||
                (selectedMonthYear[1].month === month &&
                  selectedMonthYear[1].year === currentYear)
                  ? '#3366FF50'
                  : new Date(`${currentYear} ${month}`) >=
                        new Date(
                          `${selectedMonthYear[0].year} ${selectedMonthYear[0].month}`
                        ) &&
                      new Date(`${currentYear} ${month}`) <=
                        new Date(
                          `${selectedMonthYear[1].year} ${selectedMonthYear[1].month}`
                        )
                    ? '#3366FF10'
                    : '',
              borderRadius:
                selectedMonthYear[0].month === month &&
                selectedMonthYear[0].year === currentYear
                  ? '4px 0px 0px 4px'
                  : selectedMonthYear[1].month === month &&
                      selectedMonthYear[1].year === currentYear
                    ? '0px 4px 4px 0px'
                    : '',
            }}
            onClick={() => {
              handleMonthChange(month, currentYear);
            }}>
            <span>{month}</span>
          </div>
        ))}
      </div>
    </div>
  );
}
